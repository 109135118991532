import React, { useContext } from 'react'
import { client } from '../lib/apollo'
import { gql } from 'apollo-boost'
import { AccountContext } from '../lib/AccountContext'

import PageTitle from '../components/PageTitle'
import SignIn from '../components/pages/SignIn'

const Index = () => {
  const account = useContext(AccountContext)
  return (
    <div>
      <PageTitle title={account.friendlyName} />
      <SignIn />
    </div>
  )
}
/*
// forcing home page to be SSR
const GET_ACCOUNT_BY_DOMAIN = gql`
  query GetAccountByDomain($domain: String!) {
    getAccountSettingsByDomain(domain: $domain) {
      id
      subdomain
      friendlyName
      description
    }
  }
`

export async function getServerSideProps({ req }) {
  try {
    const data = await client.query({
      query: GET_ACCOUNT_BY_DOMAIN,
      variables: {
        domain: req.headers.host
      }
    })
    return {
      props: {
        error: false,
        account: data.data.getAccountSettingsByDomain
      }
    }
  } catch (err) {
    console.log(err)
    return {
      props: {
        error: err.graphQLErrors[0].message
      }
    }
  }
}
*/
export default Index
